<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />

    <div v-if="showTimer">
      <timer-component
        :timer="timer"
        @close-modal="closeModal"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store'
// This will be populated in `beforeCreate` hook
/* eslint-disable object-curly-newline */
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useIdle, useTimestamp, useWindowSize, useCssVar } from '@vueuse/core'

import authService from '@/services/authService'

import Echo from 'laravel-echo'
import { isUserLoggedIn } from '@/auth/utils'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import TimerComponent from '@/TimerComponent.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

window.Pusher = require('pusher-js')

export default {
  components: {
    TimerComponent,
    ScrollToTop,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },

  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return store.state.appConfig.layout.type
    },
  },

  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },

  setup() {
    let now = { timestamp: ref(0) }
    // eslint-disable-next-line prefer-const
    let intervalX = ref(null)
    // eslint-disable-next-line prefer-const
    let intervalAuth = ref(null)

    if (isUserLoggedIn()) {
      now = useTimestamp()
    }

    intervalX.value = setInterval(() => {
      if (isUserLoggedIn()) {
        const data = { current_value: 'authorized' }
        authService.setNewRequest(data).then(({ resp }) => {
          const result = resp
        })
      } // clearInterval(intervalX.value)
    }, 120000) /* 1 minuto */

    const counter = ref(0)
    const auxTime = ref(0)
    const showTimer = ref(false)
    const applyTimer = ref(false)

    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const { idle, lastActive } = useIdle(7000000) // useIdle(10 * 60 * 1000), 10 minutos

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()

    const assignColors = () => {
      store.dispatch('setColorValueSidebar', '#33334d')
      store.dispatch('setColorValue', '#6abeff')
    }

    const closeModal = () => {
      showTimer.value = false
      counter.value = 0
    }

    onMounted(() => {
      assignColors()

      intervalAuth.value = setInterval(() => {
        if (isUserLoggedIn()) {
          window.Echo = new Echo({
            broadcaster: 'pusher',
            key: '15b21010c986815a1b66',
            cluster: 'us2',
            authEndpoint: 'https://api.ostrail.com/broadcasting/auth', // 'https://testapi.ostrail.com/broadcasting/auth',
            // wsHost: 'testapi.ostrail.com',
            // wssPort: 443,
            // forceTLS: true,
            // encrypted: true,
            // disableStats: true,
            // enabledTransports: ['ws', 'wss'],
            auth: {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            },
          })

          // window.Echo = new Echo({
          //   broadcaster: 'pusher',
          //   key: '923820dcc509a6f75849',
          //   authEndpoint: 'https://api.ostrail.com/broadcasting/auth',
          //   wsHost: 'notifications.ostrail.com',
          //   wsPort: 6001,
          //   wssPort: 6001,
          //   forceTLS: false,
          //   encrypted: true,
          //   disableStats: true,
          //   enabledTransports: ['ws', 'wss'],
          //   auth: {
          //     headers: {
          //       Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          //     },
          //   },
          // })

          clearInterval(intervalAuth.value)
        }
      }, 10000) /* 10 segundos */
    })

    const timer = computed(() => ((auxTime.value - now.timestamp.value) / 1000).toFixed(0))

    watch([windowWidth, idle, timer, auxTime], val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val[0])

      if (isUserLoggedIn()) {
        if (val[1]) {
          counter.value += 1

          if (counter.value == 1) {
            auxTime.value = lastActive.value + 7040000 // 10 minutos mas 60 segundos

            setTimeout(() => {
              applyTimer.value = true
              showTimer.value = true
            }, 500)
          }
        }

        if (val[2] <= 0 && applyTimer.value) {
          if (showTimer.value) {
            store.dispatch('deleteSessionInfo')
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('vuex')
            localStorage.clear()
            assignColors()
            window.location.reload()
          }
        }
      }
    })

    return {
      timer,
      showTimer,
      skinClasses,
      enableScrollToTop,
      closeModal,
    }
  },
}
</script>
