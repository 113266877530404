import axios from '@axios'
import { getClubs } from '@/services/clubServiceTest'

export default {
  state: {
    club: {}, /* si aplica */
    clubs: [],
    allClubs: [],
    users: [],
  },

  mutations: {
    setClubs(state, value) {
      state.clubs = value
    },

    setClubById(state, value) { /* si aplica, pero se debe modificar nombre a setClub */
      state.club = value
    },

    setClub(state, value) {
      state.clubs.push(value)
    },

    setAllClubs(state, list) {
      state.allClubs = list
    },

    setUsersByClubId(state, value) {
      state.users = value
    },

    updateInfoClub(state, value) {
      state.club[value.index] = value.data
    },
  },

  actions: {
    setCurrentClub({ commit }, data) { /* si aplica al cerrar sesión */
      commit('setClubById', data)
    },

    setClubsByUser({ commit }, data) { /* si aplica al cerrar sesión */
      commit('setAllClubs', data)
      commit('setClubs', data)
    },

    fetchClubs({ commit }, tempQuery) {
      return new Promise((resolve, reject) => {
        getClubs(tempQuery).then(response => {
          commit('setClubs', response.data.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    saveClub({ commit }, clubData) {
      return new Promise((resolve, reject) => {
        axios.post('/clubs', clubData).then(response => {
          commit('setClub', response.data.clubData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchClubDataAll({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/club-setting/data/all').then(response => {
          commit('setAllClubs', response.data)
          // commit('setClubs', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchUsersByClubId({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-info/${id}/users`).then(response => {
          commit('setUsersByClubId', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    updateAdminByClubId({ commit }, clubData) {
      return new Promise((resolve, reject) => {
        axios.post('/clubs-info', clubData).then(response => {
          commit('updateInfoClub', response.data)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    saveClubDataForm({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('/clubs/new-register', params).then(response => {
          commit('setClubs', response.data.clubData)
          resolve(response)
        }).catch(error => reject(error))
      })
    },

    fetchUserByClubId({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/clubs-info/${params.clubId}/users/${params.userId}`).then(response => {
          resolve(response)
        }).catch(error => reject(error))
      })
    },
  },

  getters: {
    clubList(state) {
      return state.clubs
    },

    clubInfo(state) {
      return state.club
    },

    allClubs(state) {
      return state.allClubs
    },

    usersListByClub(state) {
      return state.users
    },
  },
}
