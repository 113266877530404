import apiInstance from './index'

export default {
  async authenticate(data) {
    return await apiInstance.post('login', data)
  },

  async getStatusOfTerms() {
    return await apiInstance.get('status-of-terms')
  },

  async agreeToTerms(data) {
    return await apiInstance.post('agree-to-terms', data)
  },

  async setValidationCode(data) {
    return await apiInstance.post('validate-code', data)
  },

  async setValidationEmail(data) {
    return await apiInstance.post('validate-email', data)
  },

  async setValidationKey(data) {
    return await apiInstance.post('validate-key', data)
  },

  async setNewRequest(data) {
    return await apiInstance.post('validate-params', data)
  },
}
