import axios from '@axios'
// import gamesPlayed from '@/assets/images/dashboard/gamesPlayed.png'

export default {
  state: {
    id: null,
    name: null,
    email: null,
    fullName: null,
    avatar: null,
    gender: null,
    updatedPassword: null,
    agreeToTerms: null,
    incomplete: false,
    incompleteByImage: false,
    allPermissions: [],
    role: null,
    sport_clubs: [],
    dashboard: {},
    path: null,
  },
  getters: {
    id(state) {
      return state.id
    },
    name(state) {
      return state.name
    },
    fullName(state) {
      return state.fullName
    },
    email(state) {
      return state.email
    },
    allPermissions(state) {
      return state.allPermissions
    },
    avatar(state) {
      return state.avatar
    },
    gender(state) {
      return state.gender
    },
    updatedPassword(state) {
      return state.updatedPassword
    },
    agreeToTerms(state) {
      return state.agreeToTerms
    },
    incomplete(state) {
      return state.incomplete
    },
    incompleteByImage(state) {
      return state.incompleteByImage
    },
    role(state) {
      return state.role
    },
    sport_clubs(state) {
      return state.sport_clubs
    },
    dashboard(state) {
      return state.dashboard
    },
    path(state) {
      return state.path
    },
  },
  mutations: {
    setId(state, id) {
      state.id = id
    },
    setName(state, name) {
      state.name = name
    },
    setFullName(state, fullName) {
      state.fullName = fullName
    },
    setEmail(state, email) {
      state.email = email
    },
    setAvatar(state, avatar) {
      state.avatar = avatar
    },
    setGender(state, gender) {
      state.gender = gender
    },
    setUpdatedPassword(state, pass) {
      state.updatedPassword = pass
    },
    setAgreeToTerms(state, terms) {
      state.agreeToTerms = terms
    },
    setIncomplete(state, params) {
      state.incomplete = params
    },
    setIncompleteByImage(state, params) {
      state.incompleteByImage = params
    },
    setAllPermissions(state, permissions) {
      state.allPermissions = permissions
    },
    setRole(state, role) {
      state.role = role
    },
    setClub(state, club) {
      state.sport_clubs = club
    },
    setDashboard(state, data) {
      state.dashboard = data
    },
    setPath(state, data) {
      state.path = data
    },
  },

  actions: {
    async setInfo({ commit }, data) { /* si aplica */
      const avatar = data.user.avatar_media_file == null ? null : data.user.avatar_media_file.url_relative

      commit('setId', data.user.hash)
      commit('setName', data.user.name)
      commit('setFullName', data.user.full_name)
      commit('setAvatar', avatar)
      commit('setGender', data.user.gender_name)
      commit('setUpdatedPassword', data.user.updated_password)
      commit('setAgreeToTerms', data.user.agree_to_terms)
      commit('setIncomplete', data.user.incomplete)
      commit('setIncompleteByImage', data.user.incomplete_by_image)
      commit('setRole', data.user.role)
      commit('setClub', data.user.clubs)
      commit('setPath', data.user.path)
      commit('setDashboard', data.user.widget_configuration)

      if (data.permissions != null) {
        commit('setAllPermissions', data.permissions)
      }
    },

    async deleteSessionInfo({ commit }) {
      commit('setId', null)
      commit('setName', null)
      commit('setFullName', null)
      commit('setAvatar', null)
      commit('setGender', null)
      commit('setUpdatedPassword', null)
      commit('setAgreeToTerms', null)
      commit('setIncomplete', null)
      commit('setIncompleteByImage', null)
      commit('setRole', null)
      commit('setClub', [])
      commit('setPath', [])
      commit('setAllPermissions', [])
      commit('setDashboard', null)
    },

    async changeRole({ commit }, idClub) {
      return new Promise((resolve, reject) => {
        axios.get(`/change-role/${idClub}`).then(response => {
          const { data } = response

          if (!data) {
            reject(new Error('Fallo el cambio de rol'))
          }

          const {
            allPermissions, id, name, avatar, role, sport_clubs,
          } = data

          // roles must be a non-empty object
          if (!allPermissions || Object.keys(allPermissions).length <= 0) {
            reject(new Error('changeRole: roles must be a non-null object!'))
          }

          commit('setId', id)
          commit('setAllPermissions', allPermissions)
          commit('setName', name)
          commit('setFullName', name)
          commit('setAvatar', avatar)
          commit('setRole', role)
          commit('setClub', sport_clubs)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateUserAccount({ commit }, params) {
      return new Promise((resolve, reject) => {
        if (params.type == 1) {
          commit('setIncompleteByImage', false)
        } else {
          commit('setIncomplete', false)
        }
        resolve(true)
      })
    },
    // updateDashboard({ commit }, dashboard) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/user/update/dashboard', dashboard)
    //       .then(response => {
    //         resolve(response)
    //         commit('setDashboard', response.data)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
    // fetchDataWidget({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/widget/list')
    //       .then(response => {
    //         resolve(response)
    //         commit('setlist', response.data)
    //       })
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
