/* eslint-disable */
import axios from "axios";
import store from "@/store";
import { isUserLoggedIn } from "@/auth/utils";

/* definir url base */
// const baseURL = "http://localhost:8000/api/v1";
const baseURL = "https://api.ostrail.com/api/v1";

/* crear instancia axios */
const apiInstance = axios.create({
  baseURL: `${baseURL}/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

let x = 0

/* usar interceptor tipo request */
apiInstance.interceptors.request.use(
  (config) => {
    /* obtener token desde localStorage */
    const accessToken = localStorage.getItem("access_token");

    /* si el token existe, agregar a la cabecera de las peticiones el 'Authorization Header' */
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    /* retornar nueva configuración */
    return config;
  },
  (error) => Promise.reject(error)
);

/* usar interceptor tipo response */
apiInstance.interceptors.response.use(
  /* retornar response si todo marcha bien */
  (response) => {
      x = 0
      return response
  },
  async (error) => {
    /* asignar status */
    const status = error?.response?.status; // status es igual a 401

    if (
      status === 401 &&
      error.response.data.message[0] === "Unauthenticated."
    ) {
      if (isUserLoggedIn()) {
        /* refrescar token */
        const token = { refresh_token: localStorage.getItem("refresh_token") };

        await apiInstance
          .post("refresh-token", token)
          .then(({ data }) => {
            localStorage.setItem("access_token", data.data.access_token);
            localStorage.setItem("refresh_token", data.data.refresh_token);
          })
          .catch((error) => {
            x++
            console.log(x)
            if (x >= 15 && error.response.data.message[0] === 'The refresh token is invalid.') {
                store.dispatch('deleteSessionInfo')
                store.dispatch('setCurrentClub', {})
                store.dispatch('setClubsByUser', [])

                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')

                localStorage.removeItem('vuex')
                localStorage.clear()

                store.dispatch('setColorValueSidebar', '#33334d')
                store.dispatch('setColorValue', '#6abeff')

                // Redirect to login page
                window.location.reload(true)
            }
          });

        /* retornar petición original realizada antes del 401 */
        return apiInstance(error.config);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiInstance;
